.footer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 25px;
    padding-top: 20px;
}
.footer-logo{
    display: flex;
    align-items: center;
    gap: 15px;
}
.footer-logo p{
    color: #383838;
    font-size: 30px;
    font-weight: 700;
}
.footer-logo img{
    width: 50px;
}
.footer-links{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    list-style: none;
    gap: 40px;
    color: #252525;
    font-size: 16px;
}
.footer-links li{
    cursor: pointer;
}
.footer-social-icon{
    display: flex;
    gap: 10px;
}
.footer-icons-container{
    padding: 10px;
    padding-bottom: 6px;
    background: #fbfbfb;
    border: 1px solid #ebebeb;
}
.footer-icons-container img{
    width: 25px;
}
.footer-copyright{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    width: 100%;
    margin-bottom: 30px;
    color: #1a1a1a;
    font-size: 16px;
}
.footer-copyright hr{
    width: 80%;
    border: none;
    border-radius: 10px;
    height: 2px;
    background: #c7c7c7;
}
@media(max-width:800px){
    .footer-links{
        gap: 20px;
        font-size: 14px;
    }
}