.container_details{
    font-size: 60px;
    margin: 0 25px 0;
}
.details_img{
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;    
}
.details_submit{
    width: 50px;
    height: 50px;
    border-radius: 20px;
    background-color: green;
    padding: 0 25px 0;
    font-size: 30px;
    cursor: pointer;
    
}
.details_msg{
    font-size: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
}