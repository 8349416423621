.offers{
    margin: 100px auto;
    width: auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 0px 8%;
    margin-bottom: 150px;
    background: linear-gradient(180deg, #fff, #16a34a 50%, #fff 100%);;
}

.offers-left h1{
    color: #171717;
    font-size: 60px;
    font-weight: 600;
}
.offers-left p{
    color: #171717;
    font-size: 16px;
    font-weight: 600;
}
.offers-left button{
    width: 230px;
    height: 60px;
    border-radius: 35px;
    background: #16a34a;
    border: none;
    color: white;
    font-size: 20px;
    font-weight: 500;
    margin-top: 30px;
    cursor: pointer;
}
.offers-right img{
    width: 320px;
    padding-top: 20px;
}

/* ----- media query----- */

@media(max-width:1280px){
    .offers-right img{
        width: 300px;
    }
    .offers-left h1{
        font-size: 40px;
    }
    .offers-left p{
        font-size: 14px;
    }
    .offers-left button{
        width: 200px;
        height: 50px;
        
        font-size: 18px;
        margin-top: 25px;
    }
}
@media(max-width:1024px){
    .offers-right img{
        width: 200px;
    }
}
@media(max-width:800px){
    .offers-right{
        display: none;
    }
    .offers{
        padding: 50px 8%;
    }
}
