.carousel {
    position: relative;
    background-color: transparent;
    overflow: hidden; /* Ensure images don't overflow */
  }
  
  .carousel img {
    width: 100%; /* Make images responsive */
    height: auto; /* Maintain aspect ratio */
    transition: opacity 0.5s ease;
  }
  
  .carousel-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: transparent;
    border: none;
    cursor: pointer;
    z-index: 1; /* Ensure buttons appear on top of images */
  }
  
  .left-arrow {
    left: 10px;
  }
  
  .right-arrow {
    right: 10px;
  }
  
  @media (max-width: 768px) {
    .left-arrow,
    .right-arrow{
      font-size: 14px; 
      padding: 8px; 
      width: 35px;
    }
    .carousel img {
        height: 250px;
    }
  }

  