.container {
    width: 1730px;
    height: 323px;
    background: linear-gradient(90deg, #198F51 0.06%, #3EBB7A 52.06%, #96EABE 75.08%, #DBFFEC 94.16%);
}
.html{
    scroll-behavior: smooth;
}

.profile-img{
    position: absolute;
    display: flex;
    margin: 50px;
    width: 225px;
    height: 225px;
    background-color: #fff;
    border-radius: 100%;
}
.container-text{
    position: absolute;
    display: flex;
    color: #fff;
    font-size: 30px;
    top: 175px;
    left: 255px;
}
.edit-info{
    position: absolute;
    display: flex;
    margin: 160px 1440px 0;
    width: 163px;
    height: 55px;
    background-color: #fff;
    border-radius: 25px;
    cursor: pointer; 
}

.edit-text{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal; 
    padding: 20px;
}

.edit-container {
    position: absolute;
    width: 1284px;
    height: 471px;
    border-radius: 20px;
    background: #FFF;
    box-shadow: 4px 4px 15px 15px rgba(0, 0, 0, 0.20);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.edit-container img {
    margin: 0px 50px 0;
    flex-shrink: 0;
    object-fit: cover;
}

.back-to-main {
    position: absolute;
    top: 20px;
    left: 90%;
    color: #198F51;
    line-height: normal;
    cursor: pointer;
    width: 12rem;
}
.avatar-container{
    margin: 10px 75px 0;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15rem;
    height: 4rem;
    background-color: #198F51;
    border-radius: 1.5rem;
    border-color: transparent;
    color: #FFF;
    font-family: Inter;
    font-size: 2rem;
    font-weight: 500;
    cursor: pointer;

}
.edit-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.username-section {
    position: absolute;
    display: flex;
    width: 10rem;
    height: 3.25rem;
    background-color: #198F51;
    border-radius: 1.5rem;
    font-size: 20rem;
    font-weight: 500;
    left: 70%;
    border-color: #198F51;
}

.submit-btn {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0 0;
    width: 8rem;
    height: 3.25rem;
    background-color: #198F51;
    border-radius: 1.5rem;
    border-color: transparent;
    color: #FFF;
    font-family: Inter;
    font-size: 1.8rem;
    font-weight: 500;
    cursor: pointer;
    top: 75%;
    left: 85%;
}
.avatar-label{
    cursor: pointer;
}
.name{
    width: 25rem;
    height: 3.5rem;
    background-color: #ffffff;
    font-size: 2rem;
    color: #198F51;
    font-weight: 500;
    left: 50%;
    border-color: #198F51;
    border-color: transparent;
    border-bottom-color: #198F51;
}

.container_side {
    margin: 50px 50px 0;
    width: 25dvw; 
    height: 100%;
    border-radius: 25px;
    background: #FFF;
    box-shadow: 5px 5px 15px 15px rgba(0, 0, 0, 0.15);
    font-size: 25px;
    display: flex;
    flex-direction: column;
    justify-content: center; 
    align-items: center;
  } 

.side_text{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 50px;
}
.side_button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 20px;
    margin-bottom: 10px;
    border-radius: 25px;
    cursor: pointer;
}

.side_button.active {
    background-color: #28a745;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    scroll-behavior: smooth;
    width: 22dvw;
}