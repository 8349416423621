.fav{
    margin-left: 25px;
    
}
.fav_details{
    font-display: var(0, 0, 0,0.8);
    border: 25px;
    background-color:#4ac31a;
    width: auto;
    height: max-content;
    padding: 5px 20px 0;
    cursor: pointer;
    border-radius: 10px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    font-size: 25px;
}
.fav_details:hover{
    overflow: none;
}
.fav_img{
    
}