.addresses {
    color: black;
  }
  .addresses_text {
    font-size: 50px;
    margin: 0 25px 0;
  }
  .box {
    width: 500px;
    height: 500px;
    background: #FFF;
    box-shadow: 4px 4px 15px 15px rgba(0, 0, 0, 0.20);
    margin: 20px 50px 0;
    border-radius: 25px;
    position: relative; 
  }
  .box_button {
    margin: 25px 0 25px;
    position: absolute;
    top: 75px;
    right: 10px; 
    background-color: #4ac31a;
    box-shadow: 4px 4px 15px 15px rgba(0, 0, 0, 0.1);
    width: 150px;
    height: 50px;
    border-radius: 20px;
    cursor: pointer;
  }
  .addresses_svg {
    position: relative; 
    display: flex;
    padding: 20px px 0;
    height: 30px;
    width: 30px;
    padding: 8px 12px 0;
  }
  .box_text {
    position: relative;
    display: flex;
    padding: 25px;
    font-size: 25px ;
  }
  .text {
    display: flex;
    padding: 0 20px 0;
    font-size: 12px;
    font-weight: 200;
  }
  .box_add{
    margin: 25px 0 0;
    position: absolute;
    right: 10px; 
    background-color: #4ac31a;
    box-shadow: 4px 4px 15px 15px rgba(0, 0, 0, 0.1);
    width: 150px;
    height: 50px;
    border-radius: 20px;
    cursor: pointer;
  }